import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from 'vue-router'
import { h } from 'vue'

import Index from '@/views/Index.vue'

import authenticated from './guards/authenticated'
import notAuthenticated from './guards/notAuthenticated'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Index,
    beforeEnter: authenticated,
    children: [
      {
        path: 'settings',
        name: 'SettingsPage',
        component: () => import('@/views/Settings.vue'),
      },
      // Manager
      {
        path: 'manager/candidates',
        name: 'ManagerCandidates',
        component: () => import('@/views/Manager/Candidates.vue'),
      },
      {
        path: 'manager/vacancies',
        name: 'ManagerVacancies',
        component: () => import('@/views/Manager/Vacancies.vue'),
      },
      {
        path: 'manager/flows',
        name: 'ManagerFlows',
        component: () => import('@/views/Manager/Flows.vue'),
      },
      {
        path: 'manager/notifications',
        name: 'ManagerNotifications',
        component: () => import('@/views/Manager/Notifications.vue'),
      },
      // Supervisor
      {
        path: 'supervisor',
        name: 'SupervisorApplications',
        component: () => import('@/views/Supervisor/Index.vue'),
      },
      {
        path: 'supervisor/requests/:id',
        name: 'EditRequest',
        component: () => import('@/views/Supervisor/Requests/_id.vue'),
      },
      {
        path: 'supervisor/requests/new',
        name: 'NewRequest',
        component: () => import('@/views/Supervisor/Requests/New.vue'),
      },
      {
        path: 'supervisor/handbooks',
        name: 'Handbooks',
        component: () => import('@/views/Supervisor/Handbooks.vue'),
      },
      {
        path: 'supervisor/managers',
        name: 'SupervisorManagers',
        component: () => import('@/views/Supervisor/Managers.vue'),
      },
      // Supervisor and owner
      {
        path: 'candidates',
        name: 'CandidatesPage',
        component: () => import('@/views/SupervisorAndOwner/Candidates.vue'),
      },
      {
        path: 'event-log',
        name: 'EventLogPage',
        component: () => import('@/views/SupervisorAndOwner/EventLog.vue'),
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () => import('@/views/SupervisorAndOwner/Analytics.vue'),
      },
      // Manager and supervisor
      {
        path: 'manager-and-supervisor/candidates/:id',
        name: 'EditCandidate',
        component: () =>
          import('@/views/ManagerAndSupervisor/Candidates/_id.vue'),
      },
      {
        path: 'manager-and-supervisor/candidates/new',
        name: 'NewCandidate',
        component: () =>
          import('@/views/ManagerAndSupervisor/Candidates/New.vue'),
      },
      {
        path: 'manager-and-supervisor/flows',
        name: 'ManagerAndSupervisorFlows',
        component: () => import('@/views/ManagerAndSupervisor/Flows/Index.vue'),
      },
      {
        path: 'manager-and-supervisor/flows/:id',
        name: 'ManagerAndSupervisorFlowEdit',
        component: () => import('@/views/ManagerAndSupervisor/Flows/_id.vue'),
      },
      {
        path: 'manager-and-supervisor/events',
        name: 'ManagerEvents',
        component: () => import('@/views/ManagerAndSupervisor/Events.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: { render: () => h(RouterView) },
    beforeEnter: notAuthenticated,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Auth/Login.vue'),
      },
      {
        path: 'signup',
        name: 'Signup',
        component: () => import('@/views/Auth/Signup.vue'),
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/Auth/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/401',
    name: 'AccessDenied',
    component: () => import('@/views/NotFoundPage.vue'),
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('@/views/NotFoundPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
