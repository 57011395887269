import Api from '@/plugins/Api'
import { IState } from './types'
import { Commit } from 'vuex'
import { IDefaultStatus } from '@/types/common'

const state = (): IState => ({
  candidateStatuses: [],
})

const getters = {
  getCandidateStatuses(state: IState) {
    return state.candidateStatuses
  },
}

const mutations = {
  setCandidatesStatuses(state: IState, payload: IDefaultStatus[]) {
    state.candidateStatuses = payload
  },
}

const actions = {
  async fetchCandidatesStatuses({ commit }: { commit: Commit }) {
    try {
      const res = await Api.get<IDefaultStatus[]>(
        '/references/candidate_states'
      )
      commit('setCandidatesStatuses', res)
    } catch (error) {
      //
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
