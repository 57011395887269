
import { defineComponent } from 'vue'
import useDynamicButtons from '../ButtonsConfig/UseDynamicButtons'

import { ElButton } from 'element-plus'

export default defineComponent({
  name: 'NavItems',
  components: { ElButton },
  setup() {
    const currRole = localStorage.getItem('role') || ''

    return { ...useDynamicButtons(currRole) }
  },
})
