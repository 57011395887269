import { IParamsPayload, IState } from './types'

const state = (): IState => ({
  params: {},
})

const getters = {
  getParamsByKey(state: IState) {
    return (key: string) => state.params[key] || {}
  },
}

const mutations = {
  setParams(state: IState, payload: IParamsPayload) {
    const { key, params } = payload
    state.params[key] = params
  },
  deleteParamsByKey(state: IState, key: string) {
    state.params[key] = {}
  }
}

export default {
  state,
  getters,
  mutations,
}
