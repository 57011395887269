import { createStore } from 'vuex'

import statuses from './modules/statuses/index'
import pageParams from './modules/pageParams/index'

export default createStore({
  modules: {
    statuses,
    pageParams,
  },
})
