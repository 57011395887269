import roles from '@/enums/userRoles'

export default function (to: any, from: any, next: any) {
  const token = localStorage.getItem('access')
  const userRole = localStorage.getItem('role')

  if (!token) {
    next('/auth/login')
    return
  }

  if (to.path !== '/') {
    next()
    return
  }

  switch (userRole) {
    case roles.MANAGER:
      next('/manager/candidates')
    case roles.SUPERVISOR:
      next('/supervisor')
    default:
      next('/analytics')
      return
  }
}
