import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ae88c88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavItems = _resolveComponent("NavItems")!
  const _component_DrawerBlock = _resolveComponent("DrawerBlock")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_NavItems, { class: "header__nav-items" }),
    _createVNode(_component_DrawerBlock),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        link: "",
        type: "primary",
        tag: "router-link",
        to: "/settings"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Настройки ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        link: "",
        type: "primary",
        onClick: _ctx.logout
      }, {
        default: _withCtx(() => [
          _createTextVNode("Выйти")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}