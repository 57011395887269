import { IButton } from './types'

export const managerButtons: Array<IButton> = [
  {
    text: 'Мои кандидаты',
    url: '/manager/candidates',
  },
  {
    text: 'Мои сделки',
    url: '/manager/flows',
  },
  {
    text: 'Открытые вакансии',
    url: '/manager/vacancies',
  },
  {
    text: 'Уведомления',
    url: '/manager/notifications',
    fetchCountersUrl: '/manager/notifications/count',
  },
  {
    text: 'События',
    url: '/manager-and-supervisor/events',
  },
  {
    text: 'Новый кандидат',
    url: '/manager-and-supervisor/candidates/new',
    isNotLink: true,
  },
]

export const recruitingManagerButtons: Array<IButton> = [
  {
    text: 'Заявки',
    url: '/supervisor',
  },
  {
    text: 'Все сделки',
    url: '/manager-and-supervisor/flows',
  },
  // Общая с руководителем
  {
    text: 'Кандидаты',
    url: '/candidates',
  },
  {
    text: 'Справочники',
    url: '/supervisor/handbooks',
  },
  // Общая с руководителем
  {
    text: 'Журнал',
    url: '/event-log',
  },
  {
    text: 'Менеджеры',
    url: '/supervisor/managers',
  },
  // Общая с руководителем
  {
    text: 'Отчеты',
    url: '/analytics',
  },
  {
    text: 'Новая заявка',
    url: '/supervisor/requests/new',
    isNotLink: true,
  },
]

export const ownerButtons: Array<IButton> = [
  {
    text: 'База кандидатов',
    url: '/candidates',
  },
  {
    text: 'Аналитика',
    url: '/analytics',
  },
  {
    text: 'Журнал событий',
    url: '/event-log',
  },
]
