
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  setup() {
    const defaultTitle = 'HRM'
    const username = localStorage.getItem('username')

    document.title = username ? `${defaultTitle} | ${username}` : defaultTitle
  },
})
