
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'

import TheHeader from '@/components/Layout/TheHeader/TheHeader.vue'
import { ElConfigProvider } from 'element-plus'

import ru from 'element-plus/dist/locale/ru.mjs'
import 'dayjs/locale/ru'

export default defineComponent({
  name: 'Index',
  components: { TheHeader, ElConfigProvider },
  setup() {
    const store = useStore()

    onMounted(async () => {
      await store.dispatch('fetchCandidatesStatuses')
    })

    return {
      locale: ru,
    }
  },
})
