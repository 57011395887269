
import { defineComponent, ref } from 'vue'

import NavItems from './NavItems/NavItems.vue'
import DrawerBlock from './DrawerBlock/DrawerBlock.vue'

import { ElButton } from 'element-plus'

export default defineComponent({
  name: 'TheHeader',
  components: { NavItems, DrawerBlock, ElButton },
  setup() {
    const logout = () => {
      localStorage.removeItem('access')
      localStorage.removeItem('role')

      window.location.reload()
    }

    return { logout }
  },
})
