import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios'
import ApiInterface from '@/plugins/ApiInterface'

import { ElMessage } from 'element-plus'

class Api implements ApiInterface {
  private axios: AxiosInstance

  constructor() {
    const token = localStorage.getItem('access')
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_SERVER_URL,
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
  }

  async get<T>(url: string, params?: object): Promise<T> {
    return await this.sendResponse<T>('get', url, null, params)
  }

  async post<T>(url: string, data?: any, params?: object): Promise<T> {
    return await this.sendResponse<T>('post', url, data, params)
  }

  async put<T>(url: string, data?: any, params?: object): Promise<T> {
    return await this.sendResponse<T>('put', url, data, params)
  }

  async patch<T>(url: string): Promise<T> {
    return await this.sendResponse<T>('patch', url)
  }

  async delete<T>(url: string): Promise<T> {
    return await this.sendResponse<T>('delete', url)
  }

  private handleUnautorized() {
    localStorage.removeItem('access')
    localStorage.removeItem('username')
    localStorage.removeItem('role')

    window.location.reload()
  }

  private handleErrors(e: AxiosError) {
    if (!e.response) {
      ElMessage({
        message: 'Сервер не отвечает, попробуйте зайти позже',
        type: 'error',
        showClose: true,
      })

      return
    }

    if (e.response?.status === 401) this.handleUnautorized()
  }

  private sendResponse<T, R = null>(
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    data?: R,
    params?: object
  ): Promise<T> {
    return this.axios
      .request({
        method,
        url,
        data,
        params,
      } as AxiosRequestConfig)
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch((e: AxiosError) => {
        this.handleErrors(e)

        throw e
      })
  }
}

export default new Api()
