import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd056744"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drawer-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Grid = _resolveComponent("Grid")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_NavItems = _resolveComponent("NavItems")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_icon, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawerVisible = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Grid)
      ]),
      _: 1
    }),
    _createVNode(_component_el_drawer, {
      modelValue: _ctx.drawerVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawerVisible) = $event)),
      direction: "ltr",
      size: "300px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NavItems)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}