import { computed, onMounted, ref } from 'vue'
import {
  managerButtons,
  recruitingManagerButtons,
  ownerButtons,
} from './config'
import roles from '@/enums/userRoles'

import Api from '@/plugins/Api'

import { IButton, ICounterResponse, ICountersObject } from './types'

export default function (role: string) {
  const counters = ref<ICountersObject>({})

  const currentButtons = computed((): IButton[] => {
    if (role === roles.MANAGER) return managerButtons
    return role === roles.OWNER ? ownerButtons : recruitingManagerButtons
  })

  const fetchCounters = async (url: string) => {
    try {
      const res = await Api.get<ICounterResponse>(url)
      return res.alerts || 0
    } catch (e) {
      return 0
    }
  }

  const updateCounters = async () => {
    currentButtons.value.forEach(async (b) => {
      if (!b.fetchCountersUrl || !b.url) return

      const res = await fetchCounters(b.fetchCountersUrl)
      counters.value[b.url] = res
    })
  }

  onMounted(updateCounters)

  return { currentButtons, counters, updateCounters }
}
