import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-740f57f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-items" }
const _hoisted_2 = {
  key: 0,
  class: "nav-items__counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentButtons, (b, index) => {
      return (_openBlock(), _createBlock(_component_el_button, {
        key: index,
        link: b.isNotLink ? false : true,
        type: "primary",
        tag: "router-link",
        to: b.url,
        onClick: ($event: any) => (_ctx.updateCounters(b))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(b.text) + " ", 1),
          (_ctx.counters[b.url])
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.counters[b.url]), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["link", "to", "onClick"]))
    }), 128))
  ]))
}