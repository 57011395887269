
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { ElDrawer, ElIcon } from 'element-plus'
import NavItems from '../NavItems/NavItems.vue'
import { Grid } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'DrawerBlock',
  components: { NavItems, ElDrawer, ElIcon, Grid },
  setup() {
    const drawerVisible = ref(false)
    const route = useRoute()

    watch(
      () => route.name,
      () => (drawerVisible.value = false)
    )

    return { drawerVisible }
  },
})
